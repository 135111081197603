'use client';

import React from 'react';
import { motion } from 'framer-motion';

export default function Hero() {
  return (
    <div className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-purple-600 to-purple-800 absolute bg-noise">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center z-10"
      >
    <div className="flex justify-center items-center p-4">
        <img
          src="/charge-logo.svg"
          alt="Hero"
          width={120}
          height={40}
          className="h-5 w-auto"
        ></img>
      </div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="text-4xl md:text-6xl font-bold text-white mb-6"
        >
          Supercharge Your Tokens,
          <br />
          Maximize Your Gains
        </motion.h1>
      </motion.div>
    </div>
  );
}

