import React from "react";
import Hero from "./main/Hero";
import Features from "./main/Features";
import CTA from "./main/CTA";
import './style/Globals.css';
import Layout from "./style/Layout"
import Steps from "./main/Steps"

export default function App() {
  return (
    <Layout>
    <Hero />
    <Features />
    <Steps />
  </Layout>
  );
}

