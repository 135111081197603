'use client';

import { ReactNode, useRef, useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react';

interface LayoutProps {
  children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.scrollHeight);
    }
  }, []);

  return (
    <div className="relative min-h-screen overflow-hidden bg-purple-deep">
      <motion.div 
        className="fixed inset-0 z-0"
        style={{
          background: "linear-gradient(180deg, rgba(88,28,242,0.15) 0%, rgba(43,6,154,0.15) 100%)",
          y: backgroundY
        }}
      />

      <div 
        ref={containerRef}
        className="relative z-10 h-screen overflow-y-auto overflow-x-hidden perspective-1000"
        style={{ 
          perspective: '10px',
          transformStyle: 'preserve-3d'
        }}
      >
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement<any>, {
              style: {
                position: 'relative',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transform: `translateZ(${-10 * index}px) scale(${1 + index * 0.1})`,
                zIndex: 10 - index
              },
            });
          }
          return child;
        })}
      </div>

      <div 
        className="pointer-events-none fixed inset-0 z-20"
        style={{
        }}
      />
    </div>
  );
}

