'use client';
import React from 'react';
import { motion } from 'framer-motion';

const steps = [
  { title: "Connect Wallet", description: "Link your wallet to get started" },
  { title: "Select Tokens", description: "Choose the SPL tokens you want to charge" },
  { title: "Set Parameters", description: "Customize your charging strategy" },
  { title: "Confirm & Charge", description: "Review and confirm to start charging" }
];

export default function Steps() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 to-blue-900 flex items-center justify-center py-12">
      <div className="max-w-7xl mx-auto px-4 w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.h2 
            className="text-4xl md:text-5xl lg:text-6xl font-bold text-white leading-tight"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            Here's how you can $Charge
          </motion.h2>
          
          <div className="space-y-4">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                className="bg-purple-800/30 backdrop-blur-sm p-6 rounded-lg flex items-center gap-6"
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div
                  className="w-12 h-12 bg-purple-500 rounded-full flex items-center justify-center flex-shrink-0"
                >
                  <span className="text-white font-bold">{index + 1}</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-white mb-1">{step.title}</h3>
                  <p className="text-purple-200/80">{step.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

