'use client';

import React from 'react';
import { motion } from 'framer-motion';

const FeatureSection = ({ title, description,  }: { title: string; description: string}) => (
  <div className={`relative flex items-center justify-center h-screen w-full bg-white`}>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="space-y-6 text-center max-w-4xl px-4"
    >
      <motion.h2 
        className="text-5xl p-2 text-center font-bold from-purple-600 to-blue-600 bg-gradient-to-r bg-clip-text text-transparent"
        animate={{ 
          scale: [1, 1.05, 1],
          transition: { 
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }
        }}
      >
        {title}
      </motion.h2>
      <motion.p 
        className="text-lg md:text-xl text-gray-700 bg-transparent"
        animate={{ 
          opacity: [1, 0.8, 1],
          transition: { 
            duration: 3,
            repeat: Infinity,
            ease: "easeInOut"
          }
        }}
      >
        {description}
      </motion.p>

    </motion.div>
  </div>
);

export default function Features() {
  return (
    <>
      <FeatureSection
        title="Charge your SPL tokens"
        description="Charge your SPL tokens into leveraged powerhouses with ease"
      />
      <FeatureSection
        title="Maximize Returns"
        description="Optimize your token strategy for maximum gains"
      />
     
    </>
  );
}

